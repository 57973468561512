<template>
	<div id="app" v-if="imgList.length > 0">
		<div :style="{width: width +'PX',height: height,'border-radius':borderradius}" class="SwiperBox" ref="SwiperBox"
			@mouseenter="MouseFun('移入')" @mouseleave="MouseFun('移出')" @touchstart="touchStart" @touchend="touchEnd"
			@click.stop="juppm(imgList[imgShow])">
			<!-- 图片 -->
			<div class="imgBox" :style="{height: height,left:`-${leftVal}PX`,transition:`${ition}s`}">
				<img :style="{width: width+'PX',height: height,}" :src="item.imgUrl" v-for="(item,index) in imgList"
					:key="index" @click.stop="jump(item.id)" />
				<!-- 复制第一张放到最后,以实现无缝无线循环滚动效果 -->
				<img :style="{width: width+'PX',height: height,}" :src="imgList[0].imgUrl" alt=""
					@click.stop="jump(imgList[0].id)">
			</div>
			<!-- 左箭头按钮 -->
			<!-- <div class="leftBtn" @click="throttle(PrevFun)">&larr;</div> -->
			<!-- 右箭头按钮 -->
			<!-- <div class="rightBtn" @click="throttle(NextFun)">&rarr;</div> -->
			<!-- 下方指示点容器 -->
			<div class="instBox" v-if="type != 4 && type != 5 && type != 6">
				<div class="instBox_back" :style="{background: type != 3 ? background :'',width: width +'PX'}"
					:class="type == 1 ? 'instBox_text_disp':''">
					<div class="instBox_text">
						<div class="text"
							:style="{width:type == 1 ? 'none': width +'PX','margin-top':type == 1 ? '0':(type == 2 ? '24PX': '13PX')}"
							v-for="(item,index) in imgList" :key="index" v-if="imgShow == index"
							@click.stop="jump(item.id)">
							{{item.title}}
						</div>
					</div>
					<div class="instBox_text1"
						:style="{'margin-top':type == 2 ? '-75PX': (type == 3 ? '37PX':'10PX'),'justify-content':type == 3 ? 'flex-end':'center','padding-right':type == 3 ? '20PX':''}">
						<div @click.stop="instFun(index)" v-for="(item,index) in imgList.length" :key="index"
							:class="['inst',index==imgShow?'instActv':'']"></div>
					</div>
				</div>
			</div>
			<div class="instBox" v-else-if="type == 5">
				<div class="instBox_back" :style="{background: background ,width: width +'PX'}">
					<div class="instBox_text">
						<div class="text" :style="{width:type == 1 ? 'none': width +'PX','margin-top':'24PX'}"
							v-for="(item,index) in imgList" :key="index" v-if="imgShow == index"
							@click.stop="jump(item.id)">
							{{item.title}}
						</div>
					</div>
					<div class="instBox_text1"
						:style="{'margin-top': '-70PX','justify-content':'center','padding-right':''}">
						<div @click.stop="instFun(index)" v-for="(item,index) in imgList.length" :key="index"
							:class="['inst2',index==imgShow?'instActv2':'']"></div>
					</div>
				</div>
			</div>
			<div class="instBox" v-else-if="type == 6">
				<div class="instBox_back" :style="{background: background ,width: width +'PX'}"
					style="height: 35PX;display: flex;justify-content: space-between">
					<div class="instBox_text" style="width: 73%;">
						<div class="text"
							style="width:100%;margin-top:0;font-size:16PX;text-align: left;line-height: 35PX;"
							v-for="(item,index) in imgList" :key="index" v-if="imgShow == index"
							@click.stop="jump(item.id)">
							{{item.title}}
						</div>
					</div>
					<div class="instBox_text1"
						style="margin-top: 0; justify-content:center; padding-right:20PX;align-items: center">
						<div @click.stop="instFun(index)" v-for="(item,index) in imgList.length" :key="index"
							:class="['inst2',index==imgShow?'instActv':'']"></div>
					</div>
				</div>
			</div>
			<div class="instBox" v-else-if="type == 4">
				<div class="instBox_back" :style="{background:'',width: width +'PX'}">
					<div class="instBox_text">
						<div class="text" :style="{width:width +'PX','margin-top':'13PX'}"
							v-for="(item,index) in imgList" :key="index" v-if="imgShow == index"
							@click.stop="jump(item.id)">
							{{item.title}}
						</div>
					</div>
					<div class="instBox_text1"
						:style="{'margin-top':'37PX','justify-content':'flex-end','padding-right':'20PX'}">
						<div @click.stop="instFun(index)" v-for="(item,index) in imgList.length" :key="index"
							:class="['inst2',index==imgShow?'instActv2':'']"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			imgList: {
				type: Array,
				default: () => []
			},
			width: {
				type: [String, Number],
				default: '652'
			},
			height: {
				type: String,
				default: '380PX'
			},
			borderradius: { // 圆角
				type: String,
				default: '0'
			},
			background: { // 文字区域背景颜色
				type: String,
				default: 'rgba(7, 84, 156, 0.8)'
			},
			type: { // 轮播图样式
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				leftVal: 0, // 轮播图盒子的偏移值
				flag: true, // 用来节流防止重复点击
				start: 4000, // 自动执行下一张定的时器
				imgWidth: this.width, // 在这里填写你需要的图片宽度
				ition: 0.8, // 设置轮播图过度时间
				imgShow: 0, // 表示当前显示的图片索引

				touchStartX: 0,
				touchEndX: 0,
			};
		},
		mounted() {
			this.inft()
		},
		methods: {
			touchStart(event) { // 触摸开始时的位置
				this.touchStartX = event.touches[0].clientX;
			},

			touchEnd(event) { // 触摸结束时的位置
				this.touchEndX = event.changedTouches[0].clientX;
				this.handleSwipe();
			},
			handleSwipe() {
				const swipeDistance = this.touchEndX - this.touchStartX;
				const swipeThreshold = 10; // 较小的值会增加敏感度

				if (swipeDistance > swipeThreshold) {
					this.throttle(this.PrevFun); // 向前滑动
				} else if (swipeDistance < -swipeThreshold) {
					this.throttle(this.NextFun); // 向后滑动
				}
			},
			// 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
			MouseFun(type) { // 停止定时器            // 重新执行定时器
				type == '移入' ? clearTimeout(this.start) : this.inft()
			},
			// 此为自动轮播定时器
			inft() {
				this.start = setInterval(() => {
					this.NextFun()
				}, 4000)
			},
			// 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
			throttle(fun) {
				if (this.flag) {
					this.flag = false;
					fun(); // 此为模板中传递进来的PrevFun()或NextFun()函数
					setTimeout(() => {
						this.flag = true;
					}, 1200); // 节流间隔时间
				}
			},
			// 上一张
			PrevFun() {
				if (this.leftVal == 0) { // 判断显示的图片 是 第一张时执行
					// this.imgList.length是指循环图片数组的图片个数
					this.ition = 0 // 将过渡时间变成0，瞬间位移到最后一张图
					this.imgShow = this.imgList.length - 1 // 将高亮小点改为最后一张图
					this.leftVal = (this.imgList.length) * Number(this.imgWidth) // 瞬间移动
					setTimeout(() => { // 通过延时障眼法,归原过渡时间,执行真正的“上一张”函数
						this.ition = 0.8
						this.leftVal -= Number(this.imgWidth)
					}, this.ition * 1000)
				} else { // 判断显示的图片 不是 第一张时执行
					this.ition = 0.8
					this.leftVal -= Number(this.imgWidth)
					this.imgShow--
				}
			},
			// 下一张
			NextFun() {
				if (this.leftVal == (this.imgList.length - 1) * Number(this.imgWidth)) { // 判断显示的图片 是 最后一张时执行
					this.ition = 0
					this.leftVal += Number(this.imgWidth)
					this.imgShow = 0
					setTimeout(() => {
						this.ition = 0
						this.leftVal = 0
					}, this.ition * 1000)
				} else { // 判断显示的图片 不是 最后一张时执行
					this.ition = 0.8
					this.leftVal += Number(this.imgWidth)
					this.imgShow++
				}
			},
			// 点击小圆点
			instFun(index) {
				this.ition = 0.8
				this.leftVal = index * Number(this.imgWidth)
				this.imgShow = index
			},
			jump(row) {
				this.$emit('getclick', row)
			},
			juppm(row) {
				this.$emit('getclickm', row)
			}
		}
	};
</script>

<style lang="scss" scoped>
	/* 图片容器样式 */
	.SwiperBox {
		position: relative;
		width: 652PX;
		height: 380PX;
		box-sizing: border-box;
		cursor: pointer;
		overflow: hidden;
	}

	.imgBox {
		position: absolute;
		top: 0PX;
		left: 0PX;
		min-width: 652PX;
		height: 380PX;
		display: flex;
		justify-content: flex-start;
	}

	/* 图片默认样式 */
	.imgBox img {
		flex-shrink: 0;
		width: 652PX;
		height: 380PX;
	}

	/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
	.leftBtn,
	.rightBtn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 30PX;
		height: 30PX;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(109, 109, 109, 0.445);
		color: #fff;
		border-radius: 50%;
		cursor: pointer;
		font-size: 12PX;
		font-weight: 500;
	}

	.leftBtn {
		left: 10PX;
	}

	.rightBtn {
		right: 10PX;
	}

	/* 下方指示器盒子 */
	.instBox {
		width: 100%;
		height: 70PX;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		display: flex;
	}

	.instBox_back {
		width: 100%;
		height: 70PX;
		position: absolute;
		bottom: 0;
		left: 0;

	}

	.instBox_text {
		display: flex;
	}

	.text {
		/* width: 652PX; */
		color: #fff;
		font-size: 18PX;
		padding: 0 20PX;
		margin-top: 13PX;
		text-align: center;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.instBox_text1 {
		display: flex;
		justify-content: center;
		margin-top: 10PX;
	}

	/* 小圆点 */
	.inst {
		width: 13PX;
		height: 13PX;
		border: 1PX solid #ccc;
		margin-right: 8PX;
		background: #fff;
		border-radius: 50%;
		cursor: pointer;
	}

	.inst:last-child {
		margin-right: 0PX;
	}

	.inst2 {
		width: 10PX;
		height: 10PX;
		border: 1PX solid #ccc;
		margin-right: 8PX;
		background: #fff;
		border-radius: 50%;
		cursor: pointer;
	}

	.inst2:last-child {
		margin-right: 0PX;
	}

	.instActv {
		border: 1PX solid #15F0F0;
		background: #15F0F0;
	}

	.instActv2 {
		width: 25PX;
		height: 10PX;
		border-radius: 15PX;
	}

	.instBox_text_disp {
		width: 1200PX;
		display: flex;
		justify-content: space-between;
		padding: 0 20PX;
		align-items: center;
	}
</style>